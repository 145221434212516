<template>
  <v-list class="convenzioni-category-list">
    <v-list-item
      v-for="item in content"
      :key="item.id"
      class="convenzioni-category-list-item"
    >
      <router-link
        class="convenzioni-category-list-item-link"
        :to="{ name: 'ConvenzioniList', params: { categoryName: item.slug } }"
        v-html="item.name"
        @click="clicked"
      ></router-link>
      &nbsp;({{ item.count }})
    </v-list-item>
  </v-list>
</template>
<style lang="scss">
.convenzioni-category-list {
  padding: 16px;
  .convenzioni-category-list-item {
    min-height: 20px;
    padding: 0 16px 0 0;
  }
  .convenzioni-category-list-item-link {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    color: $text-color;
    text-decoration: none;
    &.router-link-active {
      font-weight: bold;
    }
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ConvenzioniCategory",
  data() {
    return {
      content: null
    };
  },
  mixins: [clickHandler],
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      let res = await CMSService.getCustomPostByFilters(
        "categoria_convenzioni",
        [],
        100
      );
      // Filter to remove empty custom taxonomy sets, then numerically sort (desc).
      this.content = res.data
        .filter(x => x.count > 0)
        .sort((a, b) => b.count - a.count);
    }
  }
};
</script>
