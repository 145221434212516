<template>
  <div class="convenzioni-list-container">
    <v-container class="relative">
      <h1>Convenzioni Basko</h1>
      <div class="d-flex flex-column justify-center align-center">
        <p class="text-center">
          Con Prima Card Basko sconti e vantaggi nella tua città su mostre,
          eventi, sport, salute e molto altro ancora. Richiedi la tua card, è
          gratuita!
        </p>
        <v-img
          contain
          width="300"
          src="/p/wp-content/uploads/2018/10/PrimaCard-Doppia.jpg"
        ></v-img>
      </div>

      <v-row>
        <v-col cols="0" md="4" v-if="!$vuetify.breakpoint.mobile">
          <h3 class="convenzioni-title">FILTRA LE CONVENZIONI PER:</h3>
          <convenzioni-category />
        </v-col>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mobile ? '12' : '8'"
          class="position-relative"
        >
          <div v-if="convenzioniData">
            <v-expansion-panels class="convenzioni-list">
              <v-expansion-panel v-for="item in convenzioniData" :key="item.id">
                <v-expansion-panel-header class="convenzioni-panel-header">
                  <h4 v-html="item.title.rendered" @click="clicked"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col md="4"
                      ><v-img
                        v-if="
                          item.yoast_head_json.og_image &&
                            item.yoast_head_json.og_image.length
                        "
                        :src="item.yoast_head_json.og_image[0].url"
                      ></v-img
                    ></v-col>
                    <v-col md="8"
                      ><div
                        v-html="item.content.rendered"
                        @click="clicked"
                        class="convenzioni-panel-content"
                      ></div
                    ></v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="convenzioni-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $menu
                </v-icon>
              </v-btn>
            </template>
            <v-card><ConvenzioniCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.convenzioni-page-subtitle {
  font-size: 1.5rem;
}

.convenzioni-panel-content,
.v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #000000 !important;
}
.convenzioni-list-container {
  .convenzioni-title {
    color: $primary;
  }
  .position-relative {
    position: relative;
  }
  .convenzioni-panel-header {
    // border: 1px var(--v-primary-base) solid;
    padding: 13px !important;
    background: var(--v-green-lighten2);
    h4 {
      font-size: 1rem;
      color: #000000;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 13px !important;
  }
  .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: var(--v-primary-base) !important;
  }
  .convenzioni-list {
    .v-expansion-panel-header {
      color: var(--v-primary-base) !important;
      font-weight: bold;
    }
  }
  .convenzioni-filter {
    .v-speed-dial__list {
      min-width: 400px;
      left: initial;
      right: 0;
    }
  }
}
</style>
<script>
import ConvenzioniCategory from "@/components/wordpress/ConvenzioniCategory.vue";
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ConvenzioniList",
  components: { ConvenzioniCategory },
  data() {
    return {
      convenzioniData: null,
      fab: false
    };
  },
  mixins: [clickHandler],
  mounted() {
    this.fetchConvenzioniList(this.$route.params.categoryName);
    this.fetchPage("convenzione");
  },
  methods: {
    async fetchConvenzioniList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "convenzione",
        [
          {
            filterName: "filter[categoria_convenzioni]",
            filterValue: categoryName
          }
        ],
        100
      );
      this.convenzioniData = res.data;
    }
  },

  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchConvenzioniList(categoryName);
    }
  }
};
</script>
